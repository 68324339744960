import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/about-us-header-vector.svg'
import Amoeba2 from 'assets/vectors/about-us-header-vector-mobile.svg'

export const Container = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primaryMedium};

  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (min-height: 720px) and (max-height: 1020px)  {
      height: 70vh;
    }
    @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet})  {
      height: fit-content;
      flex-direction: column-reverse;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 480px;
      flex-direction: column-reverse;
    }
  `}
`

export const TextContainer = styled.div`
  margin: 60px 120px auto 160px;

  ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.tablet}) {
        margin: 0px auto;
      }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin: 10px 28px auto 28px;
    }
  `}
`

export const Title = styled.h1`
  max-width: 600px;
  margin-bottom: 30px;

  ${({ theme }) => `
  @media (max-width: ${theme.breakPoints.tablet}) {
      max-width: 600px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3}
      max-width: 300px;
      margin-bottom: 15px;
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.backgroundLight};
  max-width: 547px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium}
      max-width: 300px;
    }
  `}
`

export const ImageContainer = styled.div`
  position: relative;
  height: 625px;
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;

  .picture {
    position: absolute;
    z-index: 2;

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 200px;
      height: 200px;
    }
  `}
  }

  ${({ theme }) => `
   @media (min-width: ${theme.breakPoints.biggestMobile}) and (max-width: ${theme.breakPoints.tablet})  {
      height: 600px;
      width: 100%;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 239px;
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  position: absolute;
  top: 0px;
  right: -93px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      height: 500px;
      right: 0px;
    }
  `}
`

export const AmoebaMobile = styled(Amoeba2)`
  position: absolute;
  top: 0px;
  right: -42px;
`
