import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/about-us-achievements-vector1.svg'
import Amoeba2 from 'assets/vectors/about-us-achievements-vector1-mobile.svg'
import Icon1 from 'assets/vectors/about-us-achievements-icon1.svg'
import Icon2 from 'assets/vectors/about-us-achievements-icon2.svg'
import Icon3 from 'assets/vectors/about-us-achievements-icon3.svg'
import Icon4 from 'assets/vectors/about-us-achievements-icon4.svg'
import Icon5 from 'assets/vectors/about-us-achievements-icon5.svg'
import Icon6 from 'assets/vectors/about-us-achievements-icon6.svg'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 40px;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 0px;
      overflow: visible;
    }
  `}
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 100px 150px 100px;
  background: ${({ theme }) => theme.colors.primaryMedium};
  border-radius: 8px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 100%;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 54px 0px 168px 28px;
      border-radius: 0px;
    }
  `}
`

export const TextContainer = styled.div`
  max-width: 600px;
  margin-bottom: 65px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: fit-content;
    }
  `}
`

export const CardContainer = styled.div``

export const CardsContainer = styled.div`
  max-width: 1044px;
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      position: relative;
      height: 300px;
    }
  `}

  .swiper-slide {
    width: unset;
  }

  .swiper-button-next-about-us-achievements {
    position: absolute;

    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.biggestMobile}) {
        bottom: -98px;
        right: 30%;
      }
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        right: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        right: 24%;
      }
    `}
  }

  .swiper-button-prev-about-us-achievements {
    position: absolute;

    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.biggestMobile}) {
        bottom: -98px;
        left: 30%;
      }
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        left: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        left: 24%;
      }
    `}
  }
`

export const Title = styled.h2`
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3}
      max-width: 250px;
      margin-bottom: 15px;
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  position: absolute;
  top: -76px;
  right: -44px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 300px;
      height: 382px;
    }
  `}
`

export const AmoebaMobile = styled(Amoeba2)`
  position: absolute;
  top: -76px;
  right: 0px;
`

export const CardIcon1 = styled(Icon1)`
  position: absolute;
  bottom: 0px;
  left: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      bottom: -14px;
      width: 100%;
      height: 100%;
    }
  `}
`

export const CardIcon2 = styled(Icon2)`
  position: absolute;
  top: -19px;
  right: 80px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 110%;
      top: -6px;
      right: 0px;
    }
  `}
`

export const CardIcon3 = styled(Icon3)`
  position: absolute;
  top: 0px;
  right: 0px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 100%;
    }
  `}
`

export const CardIcon4 = styled(Icon4)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 100%;
      bottom: -10px;
    }
  `}
`

export const CardIcon5 = styled(Icon5)`
  position: absolute;
  bottom: 0px;
  right: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 100%;
    }
  `}
`

export const CardIcon6 = styled(Icon6)`
  position: absolute;
  bottom: 0px;
  right: 0px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 100%;
    }
  `}
`

export const Card = styled.div`
  position: relative;
  width: 316px;
  height: 344px;
  padding: 6px 36px 36px;
  border-radius: 8px;
  margin-right: 32px;
  margin-bottom: 38px;
  z-index: 1;
  background: ${({ background }) => background && background};
  box-sizing: border-box;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 220px;
      height: 297px;
      margin-right: 0px;
      margin-bottom: 0px;
    }
  `}
`

export const CardText = styled.p`
  height: 150px;
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium};
      height: 110px;
    }
  `}
`

export const CardImage = styled.div`
  position: relative;
  height: 150px;
  margin-bottom: 18px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 110px;
    }
  `}
`
