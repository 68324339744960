import React from 'react'
import { withTheme } from 'styled-components'
import { SwiperSlide } from 'swiper/react'

import Carousel from 'components/Carousel'

import {
  Wrapper,
  Container,
  TextContainer,
  CardsContainer,
  CardContainer,
  Title,
  Amoeba,
  AmoebaMobile,
  CardIcon1,
  CardIcon2,
  CardIcon3,
  CardIcon4,
  CardIcon5,
  CardIcon6,
  Card,
  CardText,
  CardImage,
} from './style'

const Achievements = ({ theme, isMobile, showCarrousel }) => {
  const list = [
    {
      id: 1,
      background: theme.colors.tertiaryMedium,
      icon: <CardIcon1 />,
      text:
        'Encontros anuais, reunindo pais e amigos de crianças com Moebius, para vivências e troca de experiências, atualização de informações e palestras com especialistas.',
    },
    {
      id: 2,
      background: theme.colors.quaternaryDarkest,
      icon: <CardIcon2 />,
      text:
        'Mais de 300 casos cadastrados até hoje, na maioria dos grandes centros, além de outros estados e regiões distantes (AP, MT; GO).',
    },
    {
      id: 3,
      background: theme.colors.secondaryDarkest,
      icon: <CardIcon3 />,
      text:
        'Encontro de Estudo Colaborativo Multidisciplinar de Moebius, realizado em novembro de 2008, reunindo quase 50 casos da síndrome e 25 especialistas.',
    },
    {
      id: 4,
      background: theme.colors.secondaryDarkest,
      icon: <CardIcon4 />,
      text:
        'Intercâmbio de informações com outras associações e fundações correlatas internacionais (USA, Alemanha, Espanha, Itália, Holanda).',
    },
    {
      id: 5,
      background: theme.colors.tertiaryMedium,
      icon: <CardIcon5 />,
      text:
        'Visitas a famílias de crianças recém-nascidas com a síndrome e orientar sobre tratamento e estímulo necessários, cruciais nos primeiros anos de vida.',
    },
    {
      id: 6,
      background: theme.colors.quaternaryDarkest,
      icon: <CardIcon6 />,
      text: 'Ações beneficentes e Feijoada da AMoB.',
    },
  ]

  const Component = showCarrousel ? SwiperSlide : CardContainer

  const renderList = () =>
    list.map((item) => (
      <Component key={item.id}>
        <Card background={item.background} key={item.id}>
          <CardImage>{item.icon}</CardImage>
          <CardText>{item.text}</CardText>
        </Card>
      </Component>
    ))

  const renderCarousel = () => (
    <>
      <Carousel
        spaceBetweenCards={20}
        primaryButton
        nextButtonClass="about-us-achievements"
        prevButtonClass="about-us-achievements"
      >
        {renderList()}
      </Carousel>
    </>
  )

  return (
    <Wrapper>
      <Container>
        {typeof window !== `undefined` && isMobile && <AmoebaMobile />}
        {typeof window !== `undefined` && !isMobile && <Amoeba />}
        <TextContainer>
          <Title>Nossas realizações</Title>
        </TextContainer>
        <CardsContainer>
          {typeof window !== `undefined` && !showCarrousel && renderList()}
          {typeof window !== `undefined` && showCarrousel && renderCarousel()}
        </CardsContainer>
      </Container>
    </Wrapper>
  )
}
export default withTheme(Achievements)
