import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/AboutUs/Header'
import ResponsibleTeam from 'components/AboutUs/ResponsibleTeam'
import Achievements from 'components/AboutUs/Achievements'
import Goals from 'components/AboutUs/Goals'
import OurStory from 'components/AboutUs/OurStory'

const AboutUs = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })
  const showCarrousel = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.smallTablet})`,
  })

  const isSmallestMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.smallestMobile})`,
  })

  const isMediumMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.mediumMobile})`,
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout css={{ backgroundColor: theme.colors.primaryMedium }} isPurple>
        <Seo
          title="Quem somos"
          description="Somos a Associação Moebius do Brasil (AMoB), uma entidade que dá apoio a crianças portadoras de Síndrome de Moebius ou Seqüência de Moebius. Queremos ser um elo que conecta e acolhe portadores, familiares, amigos e especialistas, criando um espaço de pertencimento para os Moebitas e sua rede."
        />
        <Header isMobile={isMobile} />
        <ResponsibleTeam isMobile={isMobile} />
        <Achievements isMobile={isMobile} showCarrousel={showCarrousel} />
        <Goals
          isMobile={isMobile}
          isSmallestMobile={isSmallestMobile}
          isMediumMobile={isMediumMobile}
        />
        <OurStory isMobile={isMobile} />
      </Layout>
    </ThemeProvider>
  )
}

export default AboutUs
