import React from 'react'

import {
  OddContainer,
  EvenContainer,
  FirstDescription,
  FirstIlustration,
  Birds1,
  SecondIlustration,
  SecondDescription,
  Birds2,
  ThirdDescription,
  ThirdIlustration,
  Birds3,
  FourthIlustration,
  Birds4,
  FourthDescription,
  FifthDescription,
  FifthIlustration,
  Birds5,
  LastContainer,
  SixthDescription,
  Author,
  StoryFinal,
  SixthIlustration,
  Birds6,
  Birds6Mobile,
} from './style'

const OurStory = ({ isMobile }) => (
  <>
    <OddContainer>
      <FirstDescription>
        <h2>Uma história sobre a AMoB</h2>
        <p>
          Nossa história começa como a de um passarinho que acaba de chegar ao
          mundo. Quando ele nasceu foi um momento de grande alegria. Logo o
          papai e a mamãe passarinho perceberam que ele seria{' '}
          <span>muito especial</span>, pois tinha uma asinha diferente de todos
          os outros irmãozinhos do ninho. A patinha dele também era dobrada.
        </p>
      </FirstDescription>
      <FirstIlustration>
        <Birds1 />
      </FirstIlustration>
    </OddContainer>
    <EvenContainer>
      <SecondIlustration>
        <Birds2 />
      </SecondIlustration>
      <SecondDescription>
        <p>
          O papai e mamãe queriam ajudar o passarinho a sair do ninho e o
          levaram a vários<span> Doutores Passarinho</span>, nenhum deles sabia
          o que ele tinha, até que depois de muito procurar alguém falou:
        </p>
        <h3>”Ele tem uma síndrome chamada Síndrome de Moebius”</h3>
      </SecondDescription>
    </EvenContainer>
    <OddContainer>
      <ThirdDescription>
        <p>
          No começo mamãe e papai passarinho tiveram
          <span> muitas dúvidas e medo</span>. Mas logo entenderam que além da
          asinha e patinha, o passarinho poderia ter nascido com dificuldade de
          enxergar ou mesmo ter uma paralisia no rosto, que tornaria difícil o
          canto e o sorriso do passarinho.
        </p>
      </ThirdDescription>
      <ThirdIlustration>
        <Birds3 />
      </ThirdIlustration>
    </OddContainer>
    <EvenContainer>
      <FourthIlustration>
        <Birds4 />
      </FourthIlustration>
      <FourthDescription>
        <p>
          Encontrar os doutores certos para tratar do passarinho foi o início de
          uma jornada, que levou o passarinho e seus pais a uma série de
          aventuras.
        </p>
        <p>
          Todas elas acompanhadas por Doutores muito sábios que, mesmo nos
          momentos difíceis, <span>ajudaram o passarinho</span> a enxergar
          melhor, corrigir a patinha e a ter um estímulo constante.
        </p>
        <p>
          E foi assim com muita determinação, dedicação e fé, que o passarinho
          começou a <span>voar, cantar e sorrir</span>. O papai e a mamãe
          passarinho ficaram muito felizes!
        </p>
      </FourthDescription>
    </EvenContainer>
    <OddContainer>
      <FifthDescription>
        <p>
          Um dia em um passeio, os pais do passarinho{' '}
          <span>
            encontraram um pássaro com a mesma síndrome andando pela cidade.{' '}
          </span>
          E foi aí que descobriram que não estavam sozinhos!
        </p>
        <p>
          Todos os papais e mamães de passarinhos com a Síndrome de Moebius que
          eles encontravam tinham as mesmas dúvidas. O papai e a mamãe
          passarinho ficaram muito animados de
          <span> dividir tudo que sabiam </span>e apresentar os Doutores a novos
          pais.
        </p>
      </FifthDescription>
      <FifthIlustration>
        <Birds5 />
      </FifthIlustration>
    </OddContainer>
    <LastContainer>
      <SixthDescription>
        <StoryFinal>
          E foi assim que surgiu uma revoada com mais de
          <span> 300 passarinhos,</span> que juntos cruzam o céu felizes em
          grande sinfonia, porque têm uns aos outros.
        </StoryFinal>
        <Author>Baseado no texto de Reinaldo Boneto</Author>
      </SixthDescription>
      <SixthIlustration>
        {typeof window !== `undefined` && isMobile && <Birds6Mobile />}
        {typeof window !== `undefined` && !isMobile && <Birds6 />}
      </SixthIlustration>
    </LastContainer>
  </>
)

export default OurStory
