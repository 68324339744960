import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  TextContainer,
  Title,
  Description,
  ImageContainer,
  Amoeba,
  AmoebaMobile,
} from './style'

const Header = ({ isMobile }) => (
  <Container>
    <TextContainer>
      <Title>Quem somos</Title>
      <Description>
        Somos a Associação Moebius do Brasil (AMoB), uma entidade que dá apoio a
        crianças portadoras de Síndrome de Moebius ou Seqüência de Moebius.
        Queremos ser um elo que conecta e acolhe portadores, familiares, amigos
        e especialistas, criando um espaço de pertencimento para os Moebitas e
        sua rede.
      </Description>
    </TextContainer>
    <ImageContainer>
      {typeof window !== `undefined` && isMobile && <AmoebaMobile />}
      {typeof window !== `undefined` && !isMobile && <Amoeba />}
      <StaticImage
        src="../../../assets/images/home-about-picture.png"
        alt=""
        placeholder="blurred"
        className="picture"
      />
    </ImageContainer>
  </Container>
)

export default Header
