import styled from 'styled-components'
import Vector1 from 'assets/vectors/about-us-story-vector1.svg'
import Vector2 from 'assets/vectors/about-us-story-vector2.svg'
import Vector3 from 'assets/vectors/about-us-story-vector3.svg'
import Vector4 from 'assets/vectors/about-us-story-vector4.svg'
import Vector5 from 'assets/vectors/about-us-story-vector5.svg'
import Vector6 from 'assets/vectors/about-us-story-vector6.svg'
import Vector6Mobile from 'assets/vectors/about-us-story-vector6-mobile.svg'

export const OddContainer = styled.div`
  display: flex;
  width: 100%;
  height: 704px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundLight};
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      height: 1100px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 750px;
    }
  `}
`

export const EvenContainer = styled(OddContainer)`
  background: ${({ theme }) => theme.colors.tertiaryDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column-reverse;
      height: 1100px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 750px;
    }
  `}
`

export const FirstDescription = styled.div`
  max-width: 645px;
  margin-right: 30px;

  h2 {
    ${({ theme }) => theme.fonts.heading1};
    color: ${({ theme }) => theme.colors.tertiaryMedium};
    max-width: 645px;
    margin-bottom: 30px;
  }

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      padding: 104px 28px 0;
      h2 {
        ${theme.fonts.heading3};
        max-width: 350px;
        margin-bottom: 30px;
      }
      p {
        ${theme.fonts.smallTextBlack};
      }
      span {
        font-family: 'Montserrat-ExtraBold';
        font-size: 18px;
        line-height: 24px;
      }
    }
  `}
`

export const FirstIlustration = styled.div`
  max-width: 569px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const Birds1 = styled(Vector1)`
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 400px;
      transform:translateX(-10px);
    }
  `}
`

export const SecondIlustration = styled.div`
  max-width: 548px;
  margin-right: 100px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const Birds2 = styled(Vector2)`
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 400px;
    }
  `}
`

export const SecondDescription = styled.div`
  max-width: 443px;

  p {
    ${({ theme }) => theme.fonts.largeBold};
    color: ${({ theme }) => theme.colors.backgroundLight};
    max-width: 402px;
    margin-bottom: 30px;
  }

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  h3 {
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 65px 28px 0;
      p {
        ${theme.fonts.smallTextBlack};
        max-width: 305px;
        margin-bottom: 100px;
      }
      span {
        font-family: 'Montserrat-ExtraBold';
        font-size: 18px;
        line-height: 24px;
      }
      h3 {
        font-family: 'Montserrat-ExtraBold';
        font-size: 18px;
        line-height: 24px;
        max-width: 300px;
      }
    }
  `}
`

export const ThirdDescription = styled.div`
  max-width: 549px;
  margin-right: 100px;

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
  }

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 65px 28px 0;
      p {
        ${theme.fonts.smallTextBlack};
        max-width: 304px;
        margin-bottom: 120px;
      }
      span {
        font-family: 'Montserrat-ExtraBold';
        font-size: 18px;
        line-height: 24px;
      }
    }
  `}
`

export const ThirdIlustration = styled.div`
  max-width: 528px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const Birds3 = styled(Vector3)`
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 400px;
    }
  `}
`

export const FourthIlustration = styled.div`
  max-width: 559px;
  margin-right: 100px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const Birds4 = styled(Vector4)`
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 400px;
    }
  `}
`

export const FourthDescription = styled.div`
  max-width: 544px;

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.backgroundLight};
    margin-bottom: 30px;
  }

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 52px 28px 0;
      p {
        ${theme.fonts.smallTextBlack};
        max-width: 298px;
      }
      span {
        font-family: 'Montserrat-ExtraBold';
        font-size: 18px;
        line-height: 24px;
      }
    }
  `}
`

export const FifthDescription = styled.div`
  max-width: 508px;
  margin-right: 100px;

  p {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.neutralDarkest};
    margin-bottom: 30px;
  }

  span {
    ${({ theme }) => theme.fonts.largeBold};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 52px 28px 0;
      p {
        ${theme.fonts.smallTextBlack};
        max-width: 304px;
      }
      span {
        font-family: 'Montserrat-ExtraBold';
        font-size: 18px;
        line-height: 24px;
      }
    }
  `}
`

export const FifthIlustration = styled.div`
  max-width: 528px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const Birds5 = styled(Vector5)`
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      height: 400px;
    }
  `}
`

export const LastContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: ${({ theme }) => theme.colors.tertiaryDarkest};
`

export const SixthDescription = styled.div`
  max-width: 752px;
  padding-top: 100px;

  span {
    ${({ theme }) => theme.fonts.heading3};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding-left: 28px;
      padding-right: 28px;
      span {
        ${theme.fonts.heading4};
      }
    }
  `}
`

export const StoryFinal = styled.p`
  ${({ theme }) => theme.fonts.heading3};
  text-align: center;
  color: ${({ theme }) => theme.colors.backgroundLight};
  margin-bottom: 30px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading4};
      margin-bottom: 60px;
    }
  `}
`

export const Author = styled.p`
  ${({ theme }) => theme.fonts.largeText};
  text-align: center;
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-bottom: 60px;
    }
  `}
`

export const SixthIlustration = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

export const Birds6 = styled(Vector6)`
  width: 100%;
`

export const Birds6Mobile = styled(Vector6Mobile)`
  width: 100%;
`
