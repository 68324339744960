import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Container,
  TopContainer,
  BottomContainer,
  TextContainer,
  Title,
  Description,
  BottomDescription,
  TopImageContainer,
  BottomImageContainer,
  Amoeba,
  AmoebaMobile,
  Smiles,
  SmileTextContainer,
} from './style'

const ResponsibleTeam = ({ isMobile }) => (
  <Container>
    <TopContainer>
      <TopImageContainer>
        {typeof window !== `undefined` && isMobile && <AmoebaMobile />}
        {typeof window !== `undefined` && !isMobile && <Amoeba />}
        <StaticImage
          src="../../../assets/images/about-us-old-logo.png"
          alt=""
          placeholder="blurred"
          className="picture"
        />
      </TopImageContainer>
      <TextContainer>
        <Description>
          Nossa primeira reunião informal aconteceu em 30 de novembro de 1996.
          No ano seguinte, em 21 de setembro, a AMoB foi fundada oficialmente
          tendo seu estatuto aprovado como associação civil sem fins lucrativos.
          Desde a nossa fundação queremos conectar e acolher os Moebitas, por
          isso promovemos encontros anuais para troca de experiências.
        </Description>
      </TextContainer>
    </TopContainer>
    <BottomContainer>
      <TextContainer>
        <Title>Equipe Responsável</Title>
        <BottomDescription>
          Os fundadores da AMoB, <span>Marli Possamai e Reinaldo Boneto </span>
          são os responsáveis pela administração e acolhimento de Moebitas,
          familiares e parceiros da associação.
        </BottomDescription>
      </TextContainer>
      <BottomImageContainer>
        <Smiles />
        <SmileTextContainer>
          <span>
            MARLI <br /> POSSAMAI
          </span>
          <span>
            REINALDO
            <br /> BONETO
          </span>
        </SmileTextContainer>
      </BottomImageContainer>
    </BottomContainer>
  </Container>
)

export default ResponsibleTeam
