import React from 'react'
import { SwiperSlide } from 'swiper/react'

import Carousel from 'components/Carousel'

import {
  Container,
  TopContainer,
  TextContainer,
  Title,
  Description,
  ImageContainer,
  Amoeba,
  AmoebaMobile,
  BottomContainer,
  Card,
  CardTop,
  CardBottom,
  CardIcon1,
  CardIcon2,
  CardIcon3,
  CardIcon4,
  CardIcon5,
} from './style'

const Goals = ({ isMobile }) => {
  const list = [
    {
      number: 1,
      icon: <CardIcon1 />,
      text:
        'Constituir e manter um banco de dados com casos de pessoas portadoras da Síndrome de Moebius no Brasil, além de ter cadastrada toda a rede de apoio aos Moebitas - hospitais, clínicas e especialistas de diferentes áreas - localizada no Brasil ou no exterior. ',
    },
    {
      number: 2,
      icon: <CardIcon2 />,
      text:
        'Orientar crianças, pais ou responsáveis, buscando que os Moebitas sejam estimulados e reabilitados adequadamente.',
    },
    {
      number: 3,
      icon: <CardIcon3 />,
      text:
        'Buscar apoio financeiro e constituir um fundo de suporte às despesas de tratamento e outros recursos necessários para auxílio de famílias que não disponham de recursos.',
    },
    {
      number: 4,
      icon: <CardIcon4 />,
      text:
        'Constituir convênios com instituições de atendimento básico de fisioterapia, terapia ocupacional, fonoaudiologia, psicologia e outras especialidades, às crianças portadoras de Síndrome de Moebius.',
    },
    {
      icon: <CardIcon5 />,
      text:
        'Criar e manter um Boletim Informativo para os associados e interessados, a fim de relatar ocorrências e progressos relativos ao tratamento de Moebius e difundir todos os assuntos internos da AMoB.',
    },
  ]

  return (
    <Container>
      <TopContainer>
        {typeof window !== `undefined` && isMobile && <AmoebaMobile />}
        <TextContainer>
          <Title>Nossos objetivos</Title>
          <Description>
            <>
              Queremos ser um espaço de orientação e acolhimento para os
              Moebitas e seus familiares.
              <br />
              <br />
              Para que isso se torne realidade, temos cinco objetivos básicos e
              essenciais.
            </>
          </Description>
        </TextContainer>
        <ImageContainer>
          <Amoeba />
        </ImageContainer>
      </TopContainer>
      <BottomContainer>
        <Carousel
          spaceBetweenCards={isMobile ? 20 : 50}
          primaryButton
          nextButtonClass="about-us-goals"
          prevButtonClass="about-us-goals"
        >
          {list.map((item) => (
            <SwiperSlide key={item.text}>
              <Card>
                <CardTop>
                  {item.icon}
                  {!!item.number && <span>{item.number}</span>}
                </CardTop>
                <CardBottom>
                  <p>{item.text}</p>
                </CardBottom>
              </Card>
            </SwiperSlide>
          ))}
        </Carousel>
      </BottomContainer>
    </Container>
  )
}
export default Goals
