import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/about-us-goals-vector1.svg'
import Amoeba2 from 'assets/vectors/about-us-goals-vector1-mobile.svg'
import Icon1 from 'assets/vectors/about-us-goals-icon1.svg'
import Icon2 from 'assets/vectors/about-us-goals-icon2.svg'
import Icon3 from 'assets/vectors/about-us-goals-icon3.svg'
import Icon4 from 'assets/vectors/about-us-goals-icon4.svg'
import Icon5 from 'assets/vectors/about-us-goals-icon5.svg'

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-bottom: 155px;
  background: ${({ theme }) => theme.colors.backgroundMedium};
  /* ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding-bottom: 40px;
    }
  `} */
`

export const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.tablet}) {
        position: relative;
        flex-direction: column;
        align-items: center;
      }
  `}
`

export const TextContainer = styled.div`
  height: 528px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: fit-content;
      width: 100%;
      padding: 104px 28px 28px;
    }
  `}
`

export const Title = styled.h2`
  max-width: 600px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.primaryMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3}
      max-width: 200px;
      margin-bottom: 36px;
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.fonts.largeText};
  color: ${({ theme }) => theme.colors.primaryMedium};
  max-width: 432px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium}
      max-width: 300px;
    }
  `}
`

export const ImageContainer = styled.div`
  transform: translateX(-41px);

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const Amoeba = styled(Amoeba1)``

export const AmoebaMobile = styled(Amoeba2)`
  position: absolute;
  top: 0px;
  right: -97px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      right: -130px;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      right: -155px;
    }
  `}
`

export const BottomContainer = styled.div`
  position: relative;
  width: 1500px;
  padding: 50px 0px 155px 0px;
  margin-left: auto;

  .swiper-slide {
    width: unset;

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 360px;
    }
  `}
  }

  .swiper-button-next-about-us-goals {
    position: absolute;
    bottom: 0px;
    right: 44px;

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      bottom: -70px;
      right: 30%;
    }
    @media (max-width: ${theme.breakPoints.mediumMobile}) {
      right: 28%;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      right: 24%;
    }
  `}
  }

  .swiper-button-prev-about-us-goals {
    position: absolute;
    bottom: 0px;
    right: 144px;

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      bottom: -70px;
      right: unset;
      left: 30%;
    }
    @media (max-width: ${theme.breakPoints.mediumMobile}) {
      left: 28%;
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      left: 24%;
    }
  `}
  }

  @media (max-width: 1600px) {
    width: 1300px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) and (min-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding-left: 130px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      padding: 0 0 40px 28px;
    }
  `}
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 316px;
  height: 480px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 400px;
      width: 222px;
    }
  `}
`

export const CardTop = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 280px;
  align-items: center;
  margin-bottom: 30px;

  span {
    position: absolute;
    left: 95px;
    ${({ theme }) => theme.fonts.heading1};
    color: ${({ theme }) => theme.colors.primaryMedium};
  }
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
    height: 180px;

      span {
        left: 67px;
        ${theme.fonts.heading2};
      }
    }
  `}
`

export const CardBottom = styled.div`
  display: flex;
  p {
    ${({ theme }) => theme.fonts.buttomLink};
    color: ${({ theme }) => theme.colors.primaryMedium};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      p {
        ${theme.fonts.smallTextBlack};
      }
    }
  `}
`

export const CardIcon1 = styled(Icon1)``
export const CardIcon2 = styled(Icon2)``
export const CardIcon3 = styled(Icon3)``
export const CardIcon4 = styled(Icon4)``
export const CardIcon5 = styled(Icon5)``
