import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/about-us-resposible-team-vector1.svg'
import AmoebaMobile1 from 'assets/vectors/about-us-resposible-team-vector1-mobile.svg'
import Smile from 'assets/vectors/about-us-smiles.svg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundMedium};
`
export const TopContainer = styled.div`
  display: flex;
  margin-bottom: 30px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 0 28px;
      margin-bottom: 60px;
    }
  `}
`

export const BottomContainer = styled.div`
  display: flex;
  padding-bottom: 200px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      width: 600px;
      padding-bottom: 0px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      margin-bottom: 60px;
    }
  `}
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primaryDarkest};
  max-width: 550px;
  margin-bottom: 59px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading3}
      max-width: 300px;
      padding: 0 28px;
      margin-bottom: 15px;
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.neutralDarkest};
  max-width: 440px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      max-width: 600px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium};
    }
  `}
`

export const BottomDescription = styled(Description)`
  max-width: 500px;

  span {
    ${({ theme }) => theme.fonts.largeBold};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 0 28px;
      span {
        ${theme.fonts.smallTextBlack};
      }
    }
    @media (max-width: ${theme.breakPoints.tablet}) {
      max-width: unset;
    }
  `}
`

export const TopImageContainer = styled.div`
  position: relative;
  height: 582px;
  width: 500px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 62px;
  margin-right: 100px;

  .picture {
    width: 285px;
    height: 285px;
    z-index: 2;

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 183px;
      height: 183px;
      margin-top: 60px;
    }
  `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
      height: 550px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 300px;
      width: 100%;
      height: 350px;
      margin-top: 30px;
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  position: absolute;
  top: 0px;
  right: 42px;
`

export const AmoebaMobile = styled(AmoebaMobile1)`
  position: absolute;
  top: 0px;
  right: 48px;
`

export const BottomImageContainer = styled.div`
  height: 350px;
  width: 500px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
      margin-top: 50px;
      margin-left: 50px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 10px;
      margin-left: 0px;
      transform: translateX(-14px);
    }
  `}
`

export const SmileTextContainer = styled.div`
  display: flex;

  span {
    ${({ theme }) => theme.fonts.heading4};
    width: 200px;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryDarkest};
    margin-left: 26px;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      transform: translateX(3px);
      span {
        ${theme.fonts.mediumTextBold};
      }
    }
  `}
`

export const Smiles = styled(Smile)`
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`
